<template>
    <section>
        <draggable
        :list="grupos"
        class="list-group"
        @change="actualizarPosicion"
        >
            <div v-for="(g, l) in grupos" :key="l" class="card-leec row mx-0 mb-3">
                <div class="col-auto br-20 px-1 ml-2" :class="idGrupoActivo == g.id ? 'bg-general' : 'bg-white'" />
                <div class="col content-card border br-8 mx-2 py-2" @click="setGrupo(g.id)">
                    <div class="row mx-0 align-items-center pb-2">
                        <el-tooltip placement="bottom" :content="g.descripcion" effect="light">
                            <div class="col px-2">
                                <p class="text-general f-16 f-600">{{ g.nombre }}</p>
                                <p class="text-grey f-14 text-general">
                                    <i class="icon-leechero-cap" />
                                    {{ g.cant_leecheros }} {{ $config.vendedor }}
                                </p>
                                <p class="text-grey f-14 text-general">
                                    <i class="icon-geocerca" />
                                    {{ g.cant_geocercas }} Geocercas
                                </p>
                            </div>
                        </el-tooltip>
                        <el-popover placement="bottom" effect="light" class="ml-auto mr-2" popper-class="p-2 br-8">
                            <div class="row mx-0">
                                <div class="col-12 item cr-pointer py-1" @click="crearGrupo(g)">
                                    Editar
                                </div>
                                <div class="col-12 item cr-pointer py-1" @click="modalEliminar(g.id)">
                                    Eliminar
                                </div>
                            </div>
                            <div slot="reference" class="btn-action border">
                                <i v-if="$can('gestionar_asignacion_geocercas')" class="icon-dots-vertical f-20" />
                            </div>
                        </el-popover>
                    </div>
                </div>
            </div>
        </draggable>
        <!-- Partials -->
        <modal ref="modalCrearGrupo" :titulo="`Crear grupo de ${$config.vendedor}`" no-aceptar adicional="Guardar" @adicional="putGrupoGeocercas">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center">
                    <div class="col-10 my-3">
                        <small class="pl-3 text-general">
                            Nombre del grupo
                        </small>
                        <ValidationProvider v-slot="{errors}" rules="required|max:60" name="nombre">
                            <el-input v-model="form.nombre" placeholder="Nombre" maxlength="60" show-word-limit size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-10">
                        <small class="pl-3 text-general">
                            Descripción
                        </small>
                        <el-input v-model="form.descripcion" placeholder="Descripción" maxlength="60" show-word-limit type="textarea" :rows="5" class="w-100" size="small" />
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modalEliminar ref="modalEliminarGrupo" titulo="Eliminar Grupo" mensaje="¿Desea eliminar este grupo?" @eliminar="eliminarGrupo" />
    </section>
</template>

<script>
import Geocerca from '~/services/geocerca/geocerca'
export default {
    props: {
        grupos: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            idGrupoActivo: null,
            form:{
                id: '',
                nombre: '',
                descripcion: ''
            }
        }
    },
    watch: {
        idGrupo(){
            this.$emit('volver');
        }
    },
    methods: {
        crearGrupo(grupo){
            this.$refs.modalCrearGrupo.toggle();
            this.form.id = grupo.id
            this.form.nombre = grupo.nombre
            this.form.descripcion = grupo.descripcion
        },
        async putGrupoGeocercas(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }

                await Geocerca.putGrupoGeocercas(this.form.id, this.form)

                this.notificacion('','Grupo geocerca editada correctamente','success')
                this.$refs.modalCrearGrupo.toggle();
                this.$emit("update");
            } catch (e){
                this.error_catch(e)
            }
        },
        modalEliminar(idGrupo){
            this.form.id = idGrupo
            this.$refs.modalEliminarGrupo.toggle();
        },
        async eliminarGrupo(){
            try {
                await Geocerca.deleteGrupoGeocerca(this.form.id)

                this.notificacion('','Grupo geocerca eliminado correctamente','success')
                this.$refs.modalEliminarGrupo.toggle();
                this.$emit("update");
            } catch (e){
                this.error_catch(e)
            }
        },
        setGrupo(idGrupo){
            this.idGrupoActivo = idGrupo
            this.$emit("setGrupo", idGrupo)
            this.$emit('volver');
        },
        async actualizarPosicion(event){
            const datos = {
                antiguaPosicion: event.moved.oldIndex + 1,
                nuevaPosicion: event.moved.newIndex + 1,
                idGeocercaGrupo: event.moved.element.id,
            }

            await Geocerca.actualizarPosicion(datos)
            this.notificacion('','Posicion geocerca actualizado correctamente','success')
            this.$emit("update");
        }
    }
}
</script>
<style lang="scss" scoped>
.card-leec .content-card{
    &:hover{
        box-shadow: 0px 3px 6px #00000029;
    }
}
</style>
